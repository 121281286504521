import { useRouter } from 'next/router';
import {
  BreadcrumbJsonLd,
  FAQPageJsonLd,
  LocalBusinessJsonLd,
  NextSeo,
} from 'next-seo';
import React, { useEffect, useState } from 'react';

import CanonicalURL from '@/components/common/Canonical';
import CityComponent from '@/components/store-locator/city-component';
import FacilityComponent from '@/components/store-locator/facility-component';
import {
  generateDynamicBCLD,
  generateDynamicFAQ,
  generateLocalBusinessStructerData,
  getSEOProps,
} from '@/components/utility';
import PageNotFound from '@/pages/404';
import { getGoogleReviews, getValidityCity } from '@/services/home';
import SetBrandsContext from '@/services/home/setBrands';
import {
  getAllUnitsData,
  getFacilityPageData,
  getVehiclePageDataSEO,
} from '@/services/store-locator';
import { BRAND_NAME_EN, BRAND_NAME_FR, SUCCESS_CODE } from '@/utils/constants';

const Location = (props: any) => {
  const { query }: any = useRouter();
  const [facilityPage, setFacilityPage] = useState(false);
  const [cityPage, setCityPage] = useState(false);
  const [facilityName, setFacilityName] = useState('');
  const [cityName, setCityName] = useState('');
  const facilityList = props?.cityData?.data;
  const {
    hostUrl,
    vehicleLandingPage,
    facilityData,
    facilityInfo,
    unitsresultsData,
    reviewsData,
  } = props;
  const [checkQueryLen, setCheckQueryLen] = useState(null);
  const [checkCity, setCheckCity] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (query && query.location && query.location.length > 2) {
      setError(true);
    } else if (query && query.location && query.location.length === 2) {
      setCheckQueryLen(query.location.length);
      const city = query && query.location && query.location[0];
      const name = query && query.location && query.location[1];
      setFacilityPage(true);
      setCityPage(false);
      setCityName(city);
      setFacilityName(name);
      if (
        facilityList?.length === 0 &&
        props?.facilityInfo?.attributes?.multiFacility == null
      ) {
        setCheckCity(true);
      } else {
        setCheckCity(false);
      }
    } else if (query && query.location && query.location.length === 1) {
      const name1 = query && query.location && query.location[0];
      if (vehicleLandingPage) {
        setCityPage(true);
        setCityName(name1);
        setFacilityPage(false);
      } else {
        setCheckQueryLen(query.location.length);
        const filterLoc = facilityList.length === 0;
        if (filterLoc) {
          setFacilityPage(true);
          setFacilityName(name1);
          setCityPage(false);
        } else {
          setCityPage(true);
          setCityName(name1);
          setFacilityPage(false);
        }
      }
    }
  }, [query.location, facilityList]);
  const router = useRouter();
  const seoInfo = getSEOProps(props?.seoData, router);

  const faqStructuredData =
    props?.seoFaqData && generateDynamicFAQ(props?.seoFaqData?.Panel, true);
  const breadCrumbUrlList = generateDynamicBCLD(router);
  return (
    <>
      <NextSeo {...seoInfo} />
      {props?.seoFaqData && <FAQPageJsonLd mainEntity={faqStructuredData} />}
      <CanonicalURL />
      <BreadcrumbJsonLd itemListElements={breadCrumbUrlList} />
      {facilityData && unitsresultsData.length > 0 && (
        <>
          <LocalBusinessJsonLd
            {...generateLocalBusinessStructerData(
              facilityData,
              unitsresultsData,
              reviewsData,
              router
            )}
          />
        </>
      )}
      {facilityPage && facilityName && facilityName.length && (
        <FacilityComponent
          facility={facilityName}
          facilityData={facilityInfo}
          reviewsData={reviewsData}
          unitData={unitsresultsData}
          checkCity={checkCity}
          city={cityName}
          checkQueryLen={checkQueryLen}
          brandData={props?.contextData}
          promotions={props?.promotions}
        />
      )}
      {cityPage && cityName && cityName.length && (
        <CityComponent
          city={cityName}
          hostUrl={hostUrl}
          vehicleLandingPage={vehicleLandingPage}
        />
      )}
      {error && <PageNotFound />}
    </>
  );
};

export default Location;

export async function getServerSideProps(props: any) {
  const getFacilityCookie = props?.req?.cookies?.facCords;
  const host = props?.req?.headers?.host;
  const locale = props?.locale;
  let defaultBrand = BRAND_NAME_EN;
  const facilityLocation = props?.query?.location;
  let seoData: any;
  let slugSEO: any;
  if (locale === 'fr') {
    defaultBrand = BRAND_NAME_FR;
    if (facilityLocation && facilityLocation.length === 2)
      slugSEO = `${facilityLocation[1]}-fr`;
    else slugSEO = facilityLocation && facilityLocation[0];
  } else
    slugSEO =
      facilityLocation && facilityLocation.length === 1
        ? facilityLocation[0]
        : facilityLocation[1];
  let facilityData: any = {};
  let facilityInfo: any = {};
  let seoFaqData: any = null;
  let vehicleLandingPage = false;
  let unitsresultsData = [];
  let promotions = [];
  let reviewsData = [];
  let matchSlugs: any;
  let enSlug: any;
  let frSlug: any;
  if (getFacilityCookie) {
    matchSlugs = getFacilityCookie && JSON.parse(getFacilityCookie);
    const [frObj, enObj] = matchSlugs;
    enSlug = enObj && enObj.lan === 'en' ? enObj.slugValue : frObj.slugValue;
    frSlug = frObj && frObj.lan === 'fr' ? frObj.slugValue : enObj.slugValue;
  }
  const hostUrl = `${host}/${locale}`;
  let slug = props?.query?.location[0];
  if (slug && slug.substring(slug.length - 3) === '-fr') {
    slug = slug.slice(0, -3);
  }
  const cityRes = await getValidityCity(slug, 'dp');
  const cityData = await cityRes?.data;
  if (
    props.query &&
    props.query.location &&
    props.query.location.length === 2
  ) {
    const facilitySeo = await getFacilityPageData(
      slugSEO,
      defaultBrand,
      locale,
      Object.keys(props.query.location).length
    );
    seoData = facilitySeo?.data?.data[0]?.attributes?.SEO || null;
    facilityData = facilitySeo?.data?.data[0]?.attributes || null;
    facilityInfo = facilitySeo?.data?.data[0] || null;
    if (facilityData) {
      const unitsRes = await getAllUnitsData(facilityData?.Facility_ID, locale);
      unitsresultsData =
        unitsRes.status === SUCCESS_CODE ? await unitsRes?.data?.units : null;
      promotions = await unitsRes?.data?.promotions;
      const googleReviewsRes = await getGoogleReviews(
        facilityData?.Facility_ID
      );
      reviewsData =
        googleReviewsRes.status === SUCCESS_CODE
          ? await googleReviewsRes?.data
          : null;
    } else if (
      facilityData === null &&
      ((locale === 'fr' && facilityLocation[1] !== enSlug) ||
        (locale === 'en' && `${facilityLocation[1]}-fr` !== frSlug))
    ) {
      return {
        notFound: true,
      };
    }
  } else if (
    props.query &&
    props.query.location &&
    props.query.location.length === 1
  ) {
    const filterLoc = cityData && cityData.data && cityData.data.length === 0;
    if (filterLoc) {
      const facilitySeo = await getFacilityPageData(
        slugSEO,
        defaultBrand,
        locale,
        Object.keys(props.query.location).length
      );
      seoData = facilitySeo?.data?.data[0]?.attributes?.SEO || null;
      facilityData = facilitySeo?.data?.data[0]?.attributes || null;
      facilityInfo = facilitySeo?.data?.data[0] || null;
      if (facilityData) {
        const unitsRes = await getAllUnitsData(
          facilityData?.Facility_ID,
          locale
        );
        unitsresultsData =
          unitsRes.status === SUCCESS_CODE ? await unitsRes?.data?.units : null;

        promotions = await unitsRes?.data?.promotions;
        const googleReviewsRes = await getGoogleReviews(
          facilityData?.Facility_ID
        );

        reviewsData =
          googleReviewsRes.status === SUCCESS_CODE
            ? await googleReviewsRes?.data
            : null;
      } else {
        const vehicleSeo = await getVehiclePageDataSEO(
          slugSEO,
          defaultBrand,
          locale
        );
        seoData = vehicleSeo?.data?.data[0]?.attributes?.SEO || null;
        seoFaqData = vehicleSeo?.data?.data[0]?.attributes?.FAQs || null;
        const vehicleData = vehicleSeo?.data?.data[0]?.attributes || null;
        vehicleLandingPage = true;
        if (!vehicleData) {
          return {
            notFound: true,
          };
        }
      }
    } else {
      seoData = cityData?.data[0]?.attributes?.SEO || null;
      seoFaqData = cityData?.data[0]?.attributes?.FAQs || null;
    }
  }
  const contextData = await SetBrandsContext(defaultBrand, locale);

  return {
    props: {
      cityData,
      hostUrl,
      seoData,
      vehicleLandingPage,
      seoFaqData,
      facilityData,
      unitsresultsData,
      reviewsData,
      facilityInfo,
      contextData,
      promotions,
    },
  };
}
